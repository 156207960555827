import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DotsVerticalIcon from "../../assets/icons/dots-vertical.svg?react";
import EyeWhiteIcon from "../../assets/icons/eye-white.svg?react";
import PlusIcon from "../../assets/icons/plus-white.svg?react";
import TrashIcon from "../../assets/icons/trash.svg?react";
import { ChecklistList } from "../../components/Checklist/ChecklistList";
import { Button } from "../../components/Common/Button/Button";
import { InProgressPlaceholder } from "../../components/Common/Dashboard/InProgressPlaceholder";
import { ErrorMessage } from "../../components/Common/ErrorMessage/ErrorMessage";
import { Loader } from "../../components/Common/Loader/Loader";
import { Switch } from "../../components/Common/Switch/Switch";
import { RentalLayoutMenuSections } from "../../components/Layout/RentalLayout/Navbar/RentalLayoutNavbar.type";
import { RentalLayout } from "../../components/Layout/RentalLayout/RentalLayout";
import { DeleteRentalModal } from "../../components/Modal/Rental/DeleteRentalModal";
import { PaymentPolicyResponse } from "../../components/Payment/Payment.type";
import { DisableRentalModal } from "../../components/Rental/DisableRentalModal";
import { RentalDescriptionCard } from "../../components/Rental/Infos/Description/DescriptionCard";
import { RentalFacilities } from "../../components/Rental/Infos/Facilities/FacilitiesCard";
import { RentalInformationsCard } from "../../components/Rental/Infos/Informations/InformationsCard";
import { RentalLocalisationsCard } from "../../components/Rental/Infos/Localisation/LocalisationCard";
import { RentalNoteCard } from "../../components/Rental/Infos/Note/NoteCard";
import { RentalPhotosCard } from "../../components/Rental/Infos/Photos/RentalPhotosCard";
import { RentalUpdatePhotosModal } from "../../components/Rental/Infos/Photos/RentalUpdatePhotosModal";
import { RentalPlatformsCard } from "../../components/Rental/Infos/Platforms/PlatformsCard";
import { RentalReservationRulesCard } from "../../components/Rental/Infos/ReservationRules/ReservationRulesCard";
import { RentalAdditionalCost } from "../../components/Rental/Pricing/AdditionalCost/RentalAdditionalCost";
import { RentalPaymentPolicyModalMode } from "../../components/Rental/Pricing/PaymentPolicy/PaymentPolicy.type";
import { RentalPaymentPolicyCard } from "../../components/Rental/Pricing/PaymentPolicy/PaymentPolicyCard";
import { RentalPaymentPolicyModal } from "../../components/Rental/Pricing/PaymentPolicy/PaymentPolicyModal";
import { RentalPlatformRate } from "../../components/Rental/Pricing/PlatformRate/PlatformRate";
import { RentalPricingRules } from "../../components/Rental/Pricing/PricingRules/PricingRules";
import { PricelabsCard } from "../../components/Rental/Services/Pricelabs/PricelabsCard";
import { RentalSmartlocksAddModal } from "../../components/Rental/Services/Smartlocks/SmartlocksAddModal";
import { RentalSmartlocksCard } from "../../components/Rental/Services/Smartlocks/SmartlocksCard";
import { SmartlocksProvider } from "../../components/Rental/Services/Smartlocks/SmartlocksContext";
import { RentalSmartlocksDeleteModal } from "../../components/Rental/Services/Smartlocks/SmartlocksDeleteModal";
import { RentalSmartlocksUpdateModal } from "../../components/Rental/Services/Smartlocks/SmartlocksUpdateModal";
import { CgvCard } from "../../components/Rental/TravelerPage/Cgv/CgvCard";
import { CodeCard } from "../../components/Rental/TravelerPage/Code/CodeCard";
import { ContactCard } from "../../components/Rental/TravelerPage/Contact/ContactCard";
import { ExtrasCard } from "../../components/Rental/TravelerPage/Extras/ExtrasCard";
import { InstructionCard } from "../../components/Rental/TravelerPage/Instructions/InstructionCard";
import PreviewModal from "../../components/Rental/TravelerPage/Modal/PreviewModal";
import { PaymentExtartCard } from "../../components/Rental/TravelerPage/PaymentExtras/PaymentExtrasCard";
import { RequestOpinionCard } from "../../components/Rental/TravelerPage/RequestOpinion/RequestOpinionCard";
import { ResourceCard } from "../../components/Rental/TravelerPage/Resources/ResourceCard";
import { WifiCard } from "../../components/Rental/TravelerPage/Wifi/WifiCard";
import paths from "../../constants/paths";
import { get } from "../../helpers/APIHelper";
import {
  useDisableGuestPage,
  useEnableGuestPage,
} from "../../hooks/api/guestPage";
import { useCheckAuth } from "../../hooks/useCheckAuth";
import { UseModal, useModal } from "../../hooks/useModal";
import { useUrlSearchParams } from "../../hooks/useUrlSearchParams";
import {
  MessageTemplatePhotoResponse,
  RentalChecklistsResponse,
  RentalLightListItemResponse,
} from "../../types/GETTypes";
import { RentalPageProps } from "./RentalPage.type";
import { useRentalPageContext } from "./RentalPageContext";

export const RentalPage: React.FC<RentalPageProps> = () => {
  const { t } = useTranslation();
  const { rentalId } = useParams();
  const navigate = useNavigate();
  const { user } = useCheckAuth();

  const {
    loadingState: { loading, setLoading },
    rental,
  } = useRentalPageContext();

  // * -- Handle URL parameters --
  const urlParams = useUrlSearchParams<"part">();
  const partParam = urlParams.get("part")?.trim().toLowerCase();
  // const tabParam = urlParams.get("tab")?.trim().toLowerCase();

  const [loadingTravelerPage, setLoadingTravelerPage] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [rentals, setRentals] = useState<RentalLightListItemResponse[]>();
  const [navbarActiveItem, setNavbarActiveItem] =
    useState<RentalLayoutMenuSections>(
      (partParam as RentalLayoutMenuSections) ?? "infos"
    );

  // const [navbarActiveSubItem, setNavbarActiveSubItem] =
  //   useState<RentalLayoutNavbarActiveSubItemType>(
  //     (tabParam as RentalLayoutNavbarActiveSubItemType) ?? "pricing_rules"
  //   );

  const [isUpdatePhotosVisible, setIsUpdatePhotosVisible] =
    useState<boolean>(false);

  const [currentOldFiles, setCurrentOldFiles] = useState<
    MessageTemplatePhotoResponse[]
  >([]);
  const [currentNewFiles, setCurrentNewFiles] = useState<Blob[]>([]);

  const fetchRentals = async () => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}`
    );

    if (res.data.success) {
      setRentals(res.data.result.rentals);
    }
  };

  useEffect(() => {
    fetchRentals();
  }, []);

  useEffect(() => {
    if (navbarActiveItem) {
      urlParams.set("part", navbarActiveItem);
    }
  }, [navbarActiveItem]);

  // * DELETE RENTAL
  const [deleteRentalError, setDeleteRentalError] = useState<
    string | undefined
  >();
  const [deleteRentalModalVisible, setDeleteRentalModalVisible] =
    useState<boolean>(false);

  const handleDeleteRental = () => {
    setDeleteRentalError(undefined);
    setDeleteRentalModalVisible(!deleteRentalModalVisible);
  };

  const handleDeleteRentalError = (error: string | undefined) => {
    if (error) {
      setDeleteRentalError(error);
      setDeleteRentalModalVisible(false);
    }
  };

  const [activeTravelerPage, setActiveTravelerPage] = useState<boolean>(
    rental?.guest_page.enabled === 1 ? true : false
  );
  const [openPreview, setOpenPreview] = useState(false);

  // * -- PRICES TAB --
  // * PAYMENT POLICIES SECTION
  const [isVisiblePaymentPolicyModal, setIsVisiblePaymentPolicyModal] =
    useState<boolean>(false);
  const [paymentPolicyModalMode, setPaymentPolicyModalMode] = useState<
    RentalPaymentPolicyModalMode | undefined
  >(undefined);

  // * -- FETCH PAYMENT POLICIES --
  const [paymentPolicies, setPaymentPolicies] = useState<
    PaymentPolicyResponse[] | undefined
  >(undefined);
  const [paymentPoliciesError, setPaymentPoliciesError] = useState<
    string | undefined
  >();

  // * We get all Payment Policies
  const fetchPaymentPolicies = async () => {
    if (paymentPolicies === undefined && rentalId) {
      setLoading(true);
      setPaymentPoliciesError(undefined);

      const response = await get(
        `${import.meta.env.VITE_API_URL}${
          paths.API.RENTALS_UPDATE
        }/${rentalId}${paths.API.PAYMENTS.POLICIES}`
      );

      if (response.data?.success) {
        setPaymentPolicies(response?.data.result);
      } else {
        setPaymentPoliciesError(response?.data.message ?? response.data);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (navbarActiveItem === "prices") {
      fetchPaymentPolicies();
    }
  }, [navbarActiveItem]);

  // useEffect(() => {
  //   if (
  //     navbarActiveItem === "prices" &&
  //     navbarActiveSubItem === "policies_and_seasons"
  //   ) {
  //     fetchPaymentPolicies();
  //   }
  // }, [navbarActiveItem, navbarActiveSubItem]);

  useEffect(() => {
    setActiveTravelerPage(rental?.guest_page?.enabled === 1 ? true : false);
  }, [rental?.guest_page?.enabled]);

  const handleCreatePaymentPolicy = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicies) {
      setPaymentPolicies(paymentPolicies);
      setIsVisiblePaymentPolicyModal(false);
    }
  };

  const handleUpdatePaymentPolicy = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicies && paymentPolicies.length > 0) {
      setPaymentPolicies(paymentPolicies);
      setIsVisiblePaymentPolicyModal(false);
    }
  };

  const handleDeletePaymentPolicy = (
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicies) {
      setPaymentPolicies(paymentPolicies);
      setIsVisiblePaymentPolicyModal(false);
    }
  };

  const handleUpdateFavoritePaymentPolicy = (
    paymentPolicyId: number | undefined,
    paymentPolicies: PaymentPolicyResponse[]
  ) => {
    if (paymentPolicyId && paymentPolicies && paymentPolicies.length > 0) {
      setDataToUpdate(paymentPolicies.find((pp) => pp.id === paymentPolicyId));
      setPaymentPolicies(paymentPolicies);
    }
  };

  // * -- On click payment policy item --
  const [dataToUpdate, setDataToUpdate] = useState<PaymentPolicyResponse>();
  const handleClickPaymentPolicyItem = (
    paymentPolicy: PaymentPolicyResponse
  ) => {
    setPaymentPolicyModalMode("update");
    setDataToUpdate(paymentPolicy);
  };

  useEffect(() => {
    if (paymentPolicyModalMode !== undefined) {
      setIsVisiblePaymentPolicyModal(!isVisiblePaymentPolicyModal);
    }
  }, [paymentPolicyModalMode]);

  useEffect(() => {
    if (!isVisiblePaymentPolicyModal) {
      setPaymentPolicyModalMode(undefined);
    }
  }, [isVisiblePaymentPolicyModal]);

  // * END PAYMENT POLICIES

  useEffect(() => {
    if (rentalId !== undefined) {
      const getRental = async () => {
        const response = await get(
          `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}/${rentalId}`
        );

        if (response.data?.success) {
          setCurrentOldFiles(response.data.result.details.photos.photos);
        }
      };
      getRental();
    }
  }, [rentalId]);

  // * RENTAL LAYOUT
  const guestPagePreviewUrl =
    user && rental
      ? `${window.location.origin}/reservation/0/guest-page/${user.guest_page_link}?rentalId=${rental.id}`
      : "";

  const getRightChildren = () => {
    if (loading) {
      return (
        <div className="flex flex-row gap-2 animate-pulse">
          <div className="w-10 rounded h-9 bg-separator" />
        </div>
      );
    } else {
      return (
        <div className="flex flex-row justify-between w-full gap-2">
          {navbarActiveItem === "infos" ? (
            <Button
              LeftIcon={DotsVerticalIcon}
              type="secondary"
              dropItems={[
                {
                  Icon: TrashIcon,
                  value: "rental_remove",
                  label: t("Global.remove"),
                },
              ]}
              onClick={() => setDeleteRentalModalVisible(true)}
            ></Button>
          ) : null}

          {navbarActiveItem === "checklists" ? (
            <Button RightIcon={PlusIcon} onClick={upsertModal.open}>
              {t("Rental.Checklist.Upsert.title")}
            </Button>
          ) : null}

          {navbarActiveItem === "traveler" ? (
            <div className="w-[200px]">
              <Button
                RightIcon={EyeWhiteIcon}
                onClick={() => guestPagePreviewUrl && setOpenPreview(true)}
                displayLargeBtn
                disabled={!guestPagePreviewUrl}
              >
                {t("Rental.Infos.TravelerPage.openPreview")}
              </Button>
            </div>
          ) : null}
        </div>
      );
    }
  };

  // * -- CHECKLIST SECTION --
  const upsertModal: UseModal<RentalChecklistsResponse> = useModal();

  // * -- GUEST SECTION --
  const handleActiveTravelerPage = () => {
    setLoadingTravelerPage(true);
    if (activeTravelerPage) {
      useDisableGuestPage(
        rentalId,
        (response) => {
          setActiveTravelerPage(response.guest_page.enabled);
          setLoadingTravelerPage(false);
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else {
      useEnableGuestPage(
        rentalId,
        (response) => {
          setActiveTravelerPage(response.guest_page.enabled);
          setLoadingTravelerPage(false);
        },
        (error) => {
          console.log("error", error);
        }
      );
    }
  };

  return (
    <RentalLayout
      loading={loading}
      navbarActiveItem={navbarActiveItem}
      onActiveItem={(value: RentalLayoutMenuSections) =>
        setNavbarActiveItem(() => {
          urlParams.set("part", value);
          return value;
        })
      }
      // navbarActiveSubItem={navbarActiveSubItem}
      // onActiveSubItem={(value: RentalLayoutNavbarActiveSubItemType) =>
      //   setNavbarActiveSubItem(value)
      // }

      rental={rental}
      rightChildren={getRightChildren()}
    >
      <ErrorMessage>{error}</ErrorMessage>
      <ErrorMessage>{paymentPoliciesError}</ErrorMessage>
      <ErrorMessage>{deleteRentalError}</ErrorMessage>
      <PreviewModal
        isOpen={openPreview}
        onClose={() => setOpenPreview(false)}
        url={guestPagePreviewUrl}
      />
      <DeleteRentalModal
        isVisible={deleteRentalModalVisible}
        rentalId={rentalId}
        onClose={() => setDeleteRentalModalVisible(false)}
        onDelete={handleDeleteRental}
        onError={handleDeleteRentalError}
      />
      {navbarActiveItem === "infos" ? (
        <>
          <DisableRentalModal />

          <RentalUpdatePhotosModal
            isVisible={isUpdatePhotosVisible}
            rentalId={rentalId}
            oldFiles={currentOldFiles}
            newFiles={currentNewFiles}
            onClose={() => setIsUpdatePhotosVisible(false)}
          />

          <div className="flex flex-row gap-3">
            <div className="flex flex-col flex-1 gap-3">
              <RentalInformationsCard
                rentals={rentals}
                rentalInformations={rental?.details.informations}
              />

              <RentalLocalisationsCard
                rentalInformations={rental?.details.informations}
              />
              <RentalPlatformsCard
                externalConnections={rental?.details.external_connexions}
              />

              <RentalReservationRulesCard
                rentalReservationRules={rental?.details.rules}
              />

              {/* <RentalActivitiesCard loading={loading} rental={rental} /> */}
            </div>
            <div className="flex flex-col flex-1 gap-3">
              <RentalPhotosCard
                loading={loading}
                oldFiles={currentOldFiles}
                newFiles={currentNewFiles}
                onOpenUpdateModal={() => setIsUpdatePhotosVisible(true)}
              />
              <RentalDescriptionCard
                loading={loading}
                rentalId={rental?.id!}
                descriptions={rental?.details.descriptions!}
              />

              <RentalFacilities />
              <RentalNoteCard />
            </div>
          </div>
        </>
      ) : null}

      {navbarActiveItem === "prices" ? (
        <>
          <RentalPaymentPolicyModal
            mode={paymentPolicyModalMode!}
            title={
              paymentPolicyModalMode === "create"
                ? t("Rental.Pricing.PaymentPolicy.createModalTitle")
                : t("Rental.Pricing.PaymentPolicy.updateModalTitle")
            }
            isVisible={isVisiblePaymentPolicyModal}
            formData={
              paymentPolicyModalMode === "create" ? undefined : dataToUpdate
            }
            rentalId={rentalId}
            onClose={() => setIsVisiblePaymentPolicyModal(false)}
            onCreate={handleCreatePaymentPolicy}
            onUpdate={handleUpdatePaymentPolicy}
            onDelete={handleDeletePaymentPolicy}
            onUpdateFavoritePaymentPolicy={handleUpdateFavoritePaymentPolicy}
          />

          <div className="flex flex-row gap-3">
            <div className="flex flex-col flex-1 gap-3">
              <RentalPricingRules />
              <RentalPlatformRate />
            </div>
            <div className="flex flex-col flex-1 gap-3">
              {/* <RentalPaymentWays
                externalConnexions={rental?.details?.external_connexions}
                paymentPolicies={
                  rental?.tarification.policies_seasons.payment_policies
                }
                paymentWays={rental?.tarification.rules.payment_ways}
                onUpdateFavoritePaymentPolicy={
                  handleUpdateFavoritePaymentPolicy
                }
              /> */}
              <RentalAdditionalCost />
              <RentalPaymentPolicyCard
                loading={loading}
                rentalId={rentalId}
                paymentPolicies={paymentPolicies!}
                onClickActionButton={() => setPaymentPolicyModalMode("create")}
                onClickItem={handleClickPaymentPolicyItem}
                onUpdateFavoritePaymentPolicy={
                  handleUpdateFavoritePaymentPolicy
                }
              />
            </div>
          </div>
        </>
      ) : null}

      {navbarActiveItem === "contract" ? <InProgressPlaceholder /> : null}

      {navbarActiveItem === "services" ? (
        <div className="flex flex-col gap-3">
          <div className="flex flex-row flex-1 gap-3">
            <div className="flex flex-col flex-1 gap-3">
              <PricelabsCard pricelabsData={rental?.services.pricelabs} />
            </div>

            <div className="flex flex-col flex-1 gap-3">
              <SmartlocksProvider>
                <RentalSmartlocksAddModal />

                <RentalSmartlocksUpdateModal />

                <RentalSmartlocksDeleteModal />

                <RentalSmartlocksCard />
              </SmartlocksProvider>
            </div>
          </div>
        </div>
      ) : null}

      {navbarActiveItem === "invoice" ? <InProgressPlaceholder /> : null}

      {navbarActiveItem === "checklists" ? (
        <ChecklistList items={rental?.checklists} upsertModal={upsertModal} />
      ) : null}
      {navbarActiveItem === "traveler" && (
        <div className="flex flex-col gap-3">
          <div className="flex flex-row items-center justify-between p-3 space-x-3 bg-gray-100 rounded-lg">
            <p className="font-semibold ">
              {t("Rental.Infos.TravelerPage.activeTravelerPage")}
            </p>
            <div></div>
            <div className="flex flex-col gap-1">
              {loadingTravelerPage ? (
                <Loader />
              ) : (
                <Switch
                  value={activeTravelerPage}
                  onClick={() => handleActiveTravelerPage()}
                />
              )}
            </div>
          </div>

          <div className="flex flex-row flex-1 gap-3">
            <div className="flex flex-col flex-1 gap-3">
              <ContactCard
                loading={loading}
                rentals={rentals}
                rentalId={rental?.id!}
                guestPage={rental?.guest_page}
              />
              <InstructionCard
                loading={loading}
                rentals={rentals}
                rentalId={rental?.id!}
                instructions={rental?.guest_page?.instructions}
                guestPage={rental?.guest_page}
              />
              <RequestOpinionCard
                loading={loading}
                guestPage={rental?.guest_page}
                rentalId={rental?.id!}
              />
              <ResourceCard
                loading={loading}
                rentals={rentals}
                rentalId={rental?.id!}
                guest_page={rental?.guest_page}
              />
            </div>
            <div className="flex flex-col flex-1 gap-3">
              <PaymentExtartCard
                loading={loading}
                guestPage={rental?.guest_page}
                rentalId={rental?.id!}
              />
              <ExtrasCard
                loading={loading}
                guestPage={rental?.guest_page}
                rentalId={rental?.id!}
              />
              <CodeCard
                loading={loading}
                guestPage={rental?.guest_page}
                rentalId={rental?.id!}
              />
              <WifiCard
                loading={loading}
                guestPage={rental?.guest_page}
                rentalId={rental?.id!}
              />
              <CgvCard
                loading={loading}
                guestPage={rental?.guest_page}
                rentalId={rental?.id!}
              />
            </div>
          </div>
        </div>
      )}
    </RentalLayout>
  );
};

// type RentalPlatformsConnectionsCardProps = {
//   loading: boolean;
//   rental?: Rental;
// };

// const RentalPlatformsConnectionsCard: React.FC<
//   RentalPlatformsConnectionsCardProps
// > = ({ loading, rental }) => {
//   const { t } = useTranslation();

//   if (loading) return <RentalPlatformsConnectionsCardSkeleton />;

//   return (
//     <Card label={t("Rental.platformsConnections")}>
//       <div className="grid grid-cols-3 gap-3">
//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <Switch value={true} />

//             <AirbnbIcon className="w-7 h-7" />

//             <p className="font-semibold text-high-contrast">
//               {t("Rental.associateToAirbnbId")} ???????
//             </p>

//             <InfoCircleIcon className="w-4 h-4 cursor-pointer" />
//           </div>

//           <div className="flex items-center justify-center w-8 cursor-pointer bg-element-background border-l-1 border-element-border hover:bg-element-border-hover active:bg-element-background-active">
//             <CloseIcon className="w-4 h-4" />
//           </div>
//         </div>

//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <Switch value={true} />

//             <BookingIcon className="w-7 h-7" />

//             <p className="font-semibold text-high-contrast">
//               {t("Rental.associateToBookingId")} ???????
//             </p>

//             <InfoCircleIcon className="w-4 h-4 cursor-pointer" />
//           </div>

//           <div className="flex items-center justify-center w-8 cursor-pointer bg-element-background border-l-1 border-element-border hover:bg-element-border-hover active:bg-element-background-active">
//             <CloseIcon className="w-4 h-4" />
//           </div>
//         </div>
//       </div>
//     </Card>
//   );
// };

// const RentalPlatformsConnectionsCardSkeleton: React.FC<{}> = () => {
//   const { t } = useTranslation();

//   return (
//     <Card label={t("Rental.platformsConnections")}>
//       <div className="grid grid-cols-3 gap-3 animate-pulse">
//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <div className="w-10 h-6 rounded-l-full rounded-r-full bg-slate-200" />

//             <div className="rounded-full w-7 h-7 bg-slate-200" />

//             <p className="h-2 rounded w-52 bg-slate-300"></p>
//           </div>

//           <div className="flex items-center justify-center w-8 bg-element-background border-l-1 border-element-border"></div>
//         </div>

//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <div className="w-10 h-6 rounded-l-full rounded-r-full bg-slate-200" />

//             <div className="rounded-full w-7 h-7 bg-slate-200" />

//             <p className="h-2 rounded w-52 bg-slate-300"></p>
//           </div>

//           <div className="flex items-center justify-center w-8 bg-element-background border-l-1 border-element-border"></div>
//         </div>

//         <div className="flex flex-1 h-10 overflow-hidden border-1 border-element-border rounded-6px">
//           <div className="flex items-center flex-1 gap-2 px-2">
//             <div className="w-10 h-6 rounded-l-full rounded-r-full bg-slate-200" />

//             <div className="rounded-full w-7 h-7 bg-slate-200" />

//             <p className="h-2 rounded w-52 bg-slate-300"></p>
//           </div>

//           <div className="flex items-center justify-center w-8 bg-element-background border-l-1 border-element-border"></div>
//         </div>
//       </div>
//     </Card>
//   );
// };

// type RentalActivitiesCardProps = { loading: boolean; rental?: Rental };

// const RentalActivitiesCard: React.FC<RentalActivitiesCardProps> = ({
//   loading,
//   rental,
// }) => {
//   const { t } = useTranslation();

//   if (loading) return <RentalActivitiesCardSkeleton />;

//   return (
//     <Card label={t("Rental.activites")}>
//       <div className="flex flex-col">
//         <p className="font-semibold text-low-contrast">
//           {t("Rental.historical")}
//         </p>

//         <div className="mt-3">
//           <HistoricalStep
//             title="???????"
//             description="Le ??/??/???? (??:??)"
//             Icon={DollarIcon}
//           />

//           <HistoricalStep
//             title="????????"
//             description="Le ??/??/???? (??:??)"
//             Icon={SendIcon}
//             isLast={true}
//           />
//         </div>
//       </div>
//     </Card>
//   );
// };

// const RentalActivitiesCardSkeleton: React.FC<{}> = () => {
//   const { t } = useTranslation();

//   return (
//     <Card label={t("Rental.activites")}>
//       <div className="flex flex-col">
//         <p className="font-semibold text-low-contrast">
//           {t("Rental.historical")}
//         </p>

//         <div className="mt-3">
//           <HistoricalStepSkeleton />

//           <HistoricalStepSkeleton isLast={true} />
//         </div>
//       </div>
//     </Card>
//   );
// };
