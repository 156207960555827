import React, {useEffect, useState} from "react";
import {RightModal} from "../Common/RightModal/RightModal";
import {RentalLightListItemResponse, ShortcodeListItemResponse,} from "../../types/GETTypes";
import {useTranslation} from "react-i18next";
import {Button} from "../Common/Button/Button";
import {ErrorMessage} from "../Common/ErrorMessage/ErrorMessage";
import {Controller, useForm} from "react-hook-form";
import {ValueType} from "../../types/commonTypes";
import {TextInput} from "../Common/TextInput/TextInput";
import {Separator} from "../Common/Separator/Separator";
import {SimpleRadio} from "../Common/SimpleRadio/SimpleRadio";
import {TextAreaInput} from "../Common/TextAreaInput/TextAreaInput";
import paths from "../../constants/paths";
import {get, post, update} from "../../helpers/APIHelper";

type ShortcodeContentItemForm = {
  rental_id: ValueType | null;
  content: string;
};

type ShortcodeForm = {
  name: string;
  description: string;
  isGeneric: boolean;
  genericContent: string;
  rentalContents: ShortcodeContentItemForm[];
};

export const ShortcodeUpsertModal: React.FC<{
  data?: any | undefined | null;
  isVisible: boolean;
  onUpsertSuccess: () => void;
  onClose: () => void;
}> = ({ data, isVisible, onClose = () => {}, onUpsertSuccess = () => {} }) => {
  const { t } = useTranslation();
  const shortcode: ShortcodeListItemResponse = data;

  const form = useForm<ShortcodeForm>({
    defaultValues: {
      name: "",
      description: "",
      isGeneric: true,
      genericContent: "",
    },
  });

  const [rentals, setRentals] = useState<RentalLightListItemResponse[]>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchRentals = async () => {
    const res = await get(
      `${import.meta.env.VITE_API_URL}${paths.API.RENTALS}?limit=-1`
    );

    if (res?.data?.success) {
      setRentals(res?.data?.result?.rentals);

      setInitialLoading(false);
    } else {
      setError(res?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchRentals();
  }, []);

  useEffect(() => {
    if (shortcode) {
      form.setValue("name", shortcode.trigger);
      form.setValue("description", shortcode.description);
      form.setValue("isGeneric", shortcode.is_generic);

      if (shortcode.is_generic) {
        form.setValue("genericContent", shortcode.generic_content);
      }
    }

    const nextContent: ShortcodeContentItemForm[] = [];

    if ((!shortcode || shortcode.is_generic) && rentals) {
      rentals?.forEach((rental) =>
        nextContent.push({ rental_id: rental.id, content: "" })
      );
    } else if (!shortcode.is_generic && rentals) {
      rentals?.forEach((rental) => {
        const index = shortcode.rental_contents?.findIndex(
          (c) => c.rental_id === rental.id
        );
        if (index === -1) {
          nextContent.push({ rental_id: rental.id, content: "" });
        } else {
          nextContent.push({
            rental_id: rental.id,
            content: shortcode.rental_contents[index].content,
          });
        }
      });
    }

    form.setValue("rentalContents", nextContent);
  }, [rentals, shortcode]);

  const getMode = () => {
    if (shortcode) return "EDIT";
    else return "ADD";
  };

  const getTitle = () => {
    if (getMode() === "EDIT") return t("ShortcodeList.Upsert.editTitle");
    else return t("ShortcodeList.Upsert.addTitle");
  };

  const handleClose = () => {
    if (loading) return;

    setError(null);
    form.setValue("name", "");
    form.setValue("description", "");
    form.setValue("isGeneric", true);
    form.setValue("genericContent", "");
    form.setValue("rentalContents", []);

    onClose();
  };

  const handleSubmit = (values: ShortcodeForm) => {
    console.log("handleSubmit valid", values);
    if (getMode() === "ADD") handleAdd(values);
    else handleEdit(values);
  };

  const handleAdd = async (values: ShortcodeForm) => {
    setLoading(true);
    setError(null);

    let contents: ShortcodeContentItemForm[] = [];
    if (values.isGeneric) {
      contents = [{ rental_id: null, content: values.genericContent }];
    } else contents = values.rentalContents;

    const res = await post(
      `${import.meta.env.VITE_API_URL}${paths.API.SHORTCODES}`,
      {
        trigger: values.name,
        description: values.description,
        rental_contents: contents,
      }
    );

    if (res.data?.success) {
      onUpsertSuccess();
      handleClose();
    } else {
      setError(res.response?.data?.message);
    }

    setLoading(false);
  };

  const handleEdit = async (values: ShortcodeForm) => {
    setLoading(true);
    setError(null);

    let contents: ShortcodeContentItemForm[] = [];
    if (values.isGeneric) {
      contents = [{ rental_id: null, content: values.genericContent }];
    } else contents = values.rentalContents;

    const res = await update(
      `${import.meta.env.VITE_API_URL}${paths.API.SHORTCODES}/${shortcode.id}`,
      {
        trigger: values.name,
        description: values.description,
        rental_contents: contents,
      }
    );

    if (res.data?.success) {
      onUpsertSuccess();
      handleClose();
    } else {
      setError(res.response?.data?.message);
    }

    setLoading(false);
  };

  return (
    <RightModal isVisible={isVisible} title={getTitle()} onClose={handleClose}>
      <form
        className={`flex flex-col w-full`}
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <div className="w-full flex-1 overflow-y-auto">
          <Controller
            control={form.control}
            name="name"
            rules={{
              required: {
                value: true,
                message: t("ShortcodeList.Upsert.nameError"),
              },
            }}
            render={({ field }) => (
              <TextInput
                required={true}
                label={t("ShortcodeList.Upsert.name")}
                placeholder={t("ShortcodeList.Upsert.namePlaceholder")}
                value={field.value}
                onChangeText={field.onChange}
                disabled={loading || initialLoading}
                error={form.formState.errors.name?.message}
              />
            )}
          />

          <div className="h-4" />

          <Controller
            control={form.control}
            name="description"
            render={({ field }) => (
              <TextInput
                label={t("ShortcodeList.Upsert.description")}
                placeholder={t("ShortcodeList.Upsert.descriptionPlaceholder")}
                value={field.value}
                onChangeText={field.onChange}
                disabled={loading || initialLoading}
                error={form.formState.errors.description?.message}
              />
            )}
          />

          <div className="h-2" />
          <Separator />
          <div className="h-2" />
          <Controller
            control={form.control}
            name="isGeneric"
            render={({ field }) => (
              <div className="flex flex-col">
                <p className="text-low-contrast font-light">
                  {t("ShortcodeList.Upsert.askIsGeneric")}
                </p>

                <div className="flex gap-3 items-center mt-3">
                  <div
                    className="flex gap-1 items-center cursor-pointer"
                    onClick={() => field.onChange(true)}
                  >
                    <SimpleRadio value={field.value} />
                    <p
                      className={`text-light ${
                        field.value ? "text-active" : "text-low-contrast"
                      }`}
                    >
                      {t("ShortcodeList.Upsert.isGeneric")}
                    </p>
                  </div>

                  <div
                    className="flex gap-1 items-center cursor-pointer"
                    onClick={() => field.onChange(false)}
                  >
                    <SimpleRadio value={!field.value} />
                    <p
                      className={`text-light ${
                        !field.value ? "text-active" : "text-low-contrast"
                      }`}
                    >
                      {t("ShortcodeList.Upsert.isSpecific")}
                    </p>
                  </div>
                </div>
              </div>
            )}
          />

          <div className="h-4" />

          {form.watch("isGeneric") && (
            <Controller
              control={form.control}
              name="genericContent"
              rules={{
                required: {
                  value: true,
                  message: t("ShortcodeList.Upsert.genericContentError"),
                },
              }}
              render={({ field }) => (
                <TextAreaInput
                  label={t("ShortcodeList.Upsert.genericContent")}
                  placeholder={t(
                    "ShortcodeList.Upsert.genericContentPlaceholder"
                  )}
                  value={field.value}
                  required={true}
                  error={form.formState.errors.genericContent?.message}
                  onTextChange={field.onChange}
                />
              )}
            />
          )}

          {!form.watch("isGeneric") && (
            <Controller
              control={form.control}
              name="rentalContents"
              render={({ field }) => {
                return (
                  <div>
                    {rentals?.map((rental: RentalLightListItemResponse) => {
                      const getValue = () => {
                        const index: number = field.value.findIndex(
                          (f) => f.rental_id === rental.id
                        );
                        if (index === -1) return "";
                        else return field.value[index].content;
                      };

                      const handleChange = (text: string) => {
                        const index: number = field.value.findIndex(
                          (f) => f.rental_id === rental.id
                        );

                        if (index !== -1) {
                          const nextValue = field.value;
                          nextValue[index].content = text;
                          field.onChange(nextValue);
                        }
                      };

                      return (
                        <TextAreaInput
                          label={rental.name}
                          value={getValue()}
                          onTextChange={handleChange}
                        />
                      );
                    })}
                  </div>
                );
              }}
            />
          )}

          <ErrorMessage>{error}</ErrorMessage>
        </div>

        {/* Buttons */}
        <div className="flex gap-3 border-t-1 border-element-border pt-4 mt-4">
          <Button
            type="secondary"
            onClick={handleClose}
            disabled={loading || initialLoading}
          >
            {t("Global.cancel")}
          </Button>

          {getMode() === "EDIT" ? (
              <>
                <Button loading={loading || initialLoading}>
                  {t("AutoMessageList.Upsert.save")}
                </Button>
              </>

          ) : (
            <Button loading={loading || initialLoading}>
              {t("AutoMessageList.Upsert.add")}
            </Button>
          )}
        </div>
      </form>
    </RightModal>
  );
};
