import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";
import KeyIcon from "../../../assets/icons/key.svg?react";
import PlusIcon from "../../../assets/icons/plus.svg?react";
import RefreshIcon from "../../../assets/icons/refresh.svg?react";
import paths from "../../../constants/paths";
import {
  ReservationLockItemResponse,
  ReservationResponse,
} from "../../../types/GETTypes";
import { Button } from "../../Common/Button/Button";
import { Card } from "../../Common/Card/Card";
import { ReservationLockItem } from "./ReservationLockItem";

type ReservationLockCardProps = {
  reservation: ReservationResponse | undefined;
};

export const ReservationLockCard: React.FC<ReservationLockCardProps> = ({
  reservation,
}) => {
  const { t } = useTranslation();
  const [rentalId, setRentalId] = useState<ValueType | undefined>();

  const [locks, setLocks] = useState<
    ReservationLockItemResponse[] | undefined
  >();

  useEffect(() => {
    if (reservation) {
      setRentalId(reservation.rental.id);
      setLocks(reservation.locks);
    }
  }, [reservation]);

  return (
    <Card
      Icon={KeyIcon}
      label={t("Booking.Locks.title")}
      secondaryButton={{
        Icon: RefreshIcon,
        label: t("Global.refresh"),
        type: "secondary",
        disabled: true,
      }}
    >
      <>
        {!reservation ? (
          <div className="flex flex-col w-full space-y-4 animate-pulse">
            <div className="rounded h-14 bg-slate-200"></div>
            <div className="rounded h-14 bg-slate-200"></div>
            <div className="rounded h-14 bg-slate-200"></div>
          </div>
        ) : (
          <div className="flex flex-col space-y-4">
            {locks && Array.isArray(locks) && locks.length > 0 ? (
              locks.map((lock) => (
                <ReservationLockItem key={lock.id} lock={lock} />
              ))
            ) : (
              <div className="flex flex-col items-center justify-center space-y-2">
                <p className="text-low-contrast">
                  {t("Booking.Locks.noLocks")}
                </p>
                <div className="w-max">
                  <Link to={`${paths.RENTALS}/${rentalId}?part=services`}>
                    <Button type="secondary" RightIcon={PlusIcon}>
                      {t("Booking.Locks.addLock")}
                    </Button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </Card>
  );
};
