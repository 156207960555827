import React, {useEffect, useState} from "react";
import {AutoMessageUpsertContentsForm} from "./AutoMessage.type";
import {Controller, useForm} from "react-hook-form";
import {GroupButton} from "../Common/GroupButton/GroupButton";
import {useTranslation} from "react-i18next";
import FrenchIcon from "../../assets/flags/France.svg?react";
import EnglishIcon from "../../assets/flags/USA.svg?react";
import SpanishIcon from "../../assets/flags/Spain.svg?react";
import GermanIcon from "../../assets/flags/Germany.svg?react";
import {AutoMessageUpsertContentItem} from "./AutoMessageUpsertContentItem";
import {Switch} from "../Common/Switch/Switch";
import {ValueType} from "../../types/commonTypes";
import {ErrorMessage} from "../Common/ErrorMessage/ErrorMessage";
import {SimpleRadio} from "../Common/SimpleRadio/SimpleRadio";
import {MultiSelect} from "../Common/MultiSelect/MultiSelect";
import {TextInput} from "../Common/TextInput/TextInput";
import {defaultMessages} from "../../helpers/stringHelper";

export const AutoMessageUpsertContents: React.FC<{
  refButton: React.MutableRefObject<HTMLInputElement | undefined>;
  lang: ValueType;
  isShortcodesVisible: boolean;
  contents: AutoMessageUpsertContentsForm | undefined;
  onNextStep: (values: AutoMessageUpsertContentsForm) => void;
  onShortcodesVisible: () => void;
  onChangeLang: (lang: ValueType) => void;
  onFocus: (field: "message" | "title") => void;
  onChangeCursor: (index: number) => void;
  onChangeContent: (
    lang: string,
    field: "message" | "title",
    value: string
  ) => void;
}> = ({
  refButton,
  lang,
  isShortcodesVisible,
  contents,
  onNextStep,
  onShortcodesVisible,
  onChangeLang,
  onFocus,
  onChangeCursor,
  onChangeContent,
}) => {
  const {t} = useTranslation();
  const [errorLang, setErrorLang] = useState("");
  const form = useForm<AutoMessageUpsertContentsForm>({
    defaultValues: {
      name: "",
      french: defaultMessages.french,
      english: defaultMessages.english,
      spanish: defaultMessages.spanish,
      german: defaultMessages.german,
      isAllRentals: true,
      rentalsId: [],
    },
  });

  useEffect(() => {
    if (contents) {
      form.setValue("name", contents.name);
      form.setValue("french", contents.french ?? {title: "", message: ""});
      form.setValue("english", contents.english ?? {title: "", message: ""});
      form.setValue("spanish", contents.spanish ?? {title: "", message: ""});
      form.setValue("german", contents.german ?? {title: "", message: ""});
      form.setValue("isAllRentals", contents.isAllRentals);
      form.setValue("rentalsId", contents.rentalsId);
      form.setValue("name", contents.name);


    }
  }, [contents]);

  const handleNext = (values: AutoMessageUpsertContentsForm) => {
    if (
      !values.french?.message&&
      !values.english?.message &&
      !values.german?.message &&
      !values.spanish?.message
    ) {
      setErrorLang(t("AutoMessageList.Upsert.Contents.contentLangRequired"));
      return;
    }
    setErrorLang("");

    onNextStep(values);
  };

  const handleChangeLang = (nextLang: ValueType) => {
    onChangeLang(nextLang);
    onChangeCursor(0);
  };

  return (
    <form onSubmit={form.handleSubmit(handleNext)}>
      <div className="flex flex-col gap-3">
        <Controller
            control={form.control}
            name="name"
            rules={{
              required: {
                value: true,
                message: t("AutoMessageList.Upsert.General.nameError"),
              },
            }}
            render={({ field: { value, onChange } }) => (
                <TextInput
                    label={t("AutoMessageList.Upsert.General.name")}
                    placeholder={t("AutoMessageList.Upsert.General.namePlaceholder")}
                    required={true}
                    value={value}
                    size="large"
                    error={form.formState.errors.name?.message}
                    onChangeText={onChange}
                />
            )}
        />
        <GroupButton
          items={[
            {
              label: t("AutoMessageList.Upsert.Contents.french"),
              value: "french",
              Icon: FrenchIcon,
              isActive: lang === "french",
            },
            {
              label: t("AutoMessageList.Upsert.Contents.english"),
              value: "english",
              Icon: EnglishIcon,
              isActive: lang === "english",
            },
            {
              label: t("AutoMessageList.Upsert.Contents.spanish"),
              value: "spanish",
              Icon: SpanishIcon,
              isActive: lang === "spanish",
            },
            {
              label: t("AutoMessageList.Upsert.Contents.german"),
              value: "german",
              Icon: GermanIcon,
              isActive: lang === "german",
            },
          ]}
          onClick={handleChangeLang}
        />

        {lang === "french" && (
          <Controller
            control={form.control}
            name="french"
            render={({field: {value, onChange}}) => {
              const handleChangeTitle = (title: string) => {
                onChange({...value, title});
                onChangeContent(lang, "title", title);
              };

              const handleChangeMessage = (message: string) => {
                onChange({...value, message});
                onChangeContent(lang, "message", message);
              };

              return (
                <AutoMessageUpsertContentItem
                  object={value?.title ?? ""}
                  message={value?.message ?? ""}
                  required={true}
                  //@ts-ignore
                  error={form.formState.errors.french?.message?.message}
                  onChangeObject={handleChangeTitle}
                  onChangeMessage={handleChangeMessage}
                  onFocusObject={() => onFocus("title")}
                  onFocusMessage={() => onFocus("message")}
                  onChangeCursor={onChangeCursor}
                />
              );
            }}
          />
        )}

        {lang === "english" && (
          <Controller
            control={form.control}
            name="english"
            render={({field: {value, onChange}}) => {
              const handleChangeTitle = (title: string) => {
                onChange({...value, title});
                onChangeContent(lang, "title", title);
              };

              const handleChangeMessage = (message: string) => {
                onChange({...value, message});
                onChangeContent(lang, "message", message);
              };

              return (
                <AutoMessageUpsertContentItem
                  object={value?.title ?? ""}
                  message={value?.message ?? ""}
                  required={false}
                  onChangeObject={handleChangeTitle}
                  onChangeMessage={handleChangeMessage}
                  onFocusObject={() => onFocus("title")}
                  onFocusMessage={() => onFocus("message")}
                  onChangeCursor={onChangeCursor}
                />
              );
            }}
          />
        )}

        {lang === "spanish" && (
          <Controller
            control={form.control}
            name="spanish"
            render={({field: {value, onChange}}) => {
              const handleChangeTitle = (title: string) => {
                onChange({...value, title});
                onChangeContent(lang, "title", title);
              };

              const handleChangeMessage = (message: string) => {
                onChange({...value, message});
                onChangeContent(lang, "message", message);
              };

              return (
                <AutoMessageUpsertContentItem
                  object={value?.title ?? ""}
                  message={value?.message ?? ""}
                  required={false}
                  onChangeObject={handleChangeTitle}
                  onChangeMessage={handleChangeMessage}
                  onFocusObject={() => onFocus("title")}
                  onFocusMessage={() => onFocus("message")}
                  onChangeCursor={onChangeCursor}
                />
              );
            }}
          />
        )}

        {lang === "german" && (
          <Controller
            control={form.control}
            name="german"
            render={({field: {value, onChange}}) => {
              const handleChangeTitle = (title: string) => {
                onChange({...value, title});
                onChangeContent(lang, "title", title);
              };

              const handleChangeMessage = (message: string) => {
                onChange({...value, message});
                onChangeContent(lang, "message", message);
              };

              return (
                <AutoMessageUpsertContentItem
                  object={value?.title ?? ""}
                  message={value?.message ?? ""}
                  required={false}
                  onChangeObject={handleChangeTitle}
                  onChangeMessage={handleChangeMessage}
                  onFocusObject={() => onFocus("title")}
                  onFocusMessage={() => onFocus("message")}
                  onChangeCursor={onChangeCursor}
                />
              );
            }}
          />
        )}

        <div
          className="flex justify-between items-center font-light text-low-contrast cursor-pointer"
          onClick={onShortcodesVisible}
        >
          <p>{t("AutoMessageList.Upsert.Contents.shortcodes")}</p>

          <Switch value={isShortcodesVisible} />
        </div>

        <Controller
            control={form.control}
            name="isAllRentals"
            render={({ field: { value, onChange } }) => (
                <div className="flex flex-col gap-3 text-low-contrast font-light">
                  <p>{t("AutoMessageList.Upsert.General.rentals")}</p>

                  <div className="flex gap-1">
                    <SimpleRadio
                        value={value === true}
                        onClick={() => onChange(true)}
                    />
                    <p
                        className="mr-2 cursor-pointer"
                        onClick={() => onChange(true)}
                    >
                      {t("AutoMessageList.Upsert.General.allRentals")}
                    </p>

                    <SimpleRadio
                        value={value === false}
                        onClick={() => onChange(false)}
                    />
                    <p onClick={() => onChange(false)} className="cursor-pointer">
                      {t("AutoMessageList.Upsert.General.fewRentals")}
                    </p>
                  </div>
                </div>
            )}
        />

        {form.watch("isAllRentals") === false && (
            <Controller
                control={form.control}
                name="rentalsId"
                render={({ field: { value, onChange } }) => {
                  const handleChange = (id: ValueType) => {
                    const index = value.findIndex((v) => v === id);
                    if (index === -1) onChange([...value, id]);
                    else onChange(value.filter((v) => v !== id));
                  };

                  return (
                      <MultiSelect
                          options={{ withInfoText: false, withSubmitButton: false }}
                          loading={false}
                          onSelectRental={handleChange}
                          selectedRentals={value}
                      />
                  );
                }}
            />
        )}

        <ErrorMessage>{errorLang}</ErrorMessage>
      </div>

      <input
        type="submit"
        value="go"
        ref={refButton as React.MutableRefObject<HTMLInputElement>}
        className="hidden"
      />
    </form>
  );
};
